import React , {useState} from 'react'
import './Payment.css'
import trainings from '../../variables/trainings'
import card_icon from "../../images/card-icon.svg"

import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify' 
import CardPaymentWrapper from '../../components/CardPaymentWrapper/CardPaymentWrapper'
import MobilePaymentWapper from '../../components/MobilePaymentWrapper/MobilePaymentWrapper'


export default function StandAlonePayment() {

    const [option , setOption] = useState('mobile')
    const [ error , setError ] = useState("")
    const [amount , setAmount]  = useState('')
    const [courseName , setCourseName] = useState('')

    const courses = trainings.map( training => training.name )

    const handleRadioChange = (e) => {
        console.log(e.target.checked)
        setOption(e.target.value)
    }
  
  
  return (
    <div className='payment-page section-spacing'>
        <div className="container pt-5 ">
            <div className="payment-wrapper">
                <div className="section-heading mb-5">
                    <h6>Payment Options</h6>
                    <h1>Select Your Payment Method</h1>
                    <p className='mt-4 fs-5'> To finish any of your pending payments , please make a transaction using one of the methods below</p>
                </div>
                <div className="row flex-column-reverse flex-md-row gap-5 gap-md-0">
                    <div className="col-md-6">
                        <div className="payment-options">
                            <div className="payment-option">
                                <div className="payment-option-header d-flex align-items-center">
                                    <input type="radio" value={'mobile'} onChange={handleRadioChange} defaultChecked={option === 'mobile'} name='option' style={{minWidth:'50px'  , minHeight : '20px'}} />
                                    <div>
                                        <img src={card_icon} width={'45px'} alt="" />
                                    </div>
                                <h5 className='mb-0 fw-bold ms-2'>JazzCash Mobile Number</h5> 
                                </div>
                            </div>
                            <div className="payment-option mt-3">
                                <div className="payment-option-header d-flex align-items-center">
                                    <input type="radio" value={'card'} onChange={handleRadioChange} name='option'  style={{minWidth:'50px' , minHeight : '20px'}} />
                                    <div>
                                        <img src={card_icon} width={'45px'} alt="" />
                                    </div>
                                <h5 className='mb-0 fw-bold ms-2'>Credit/Debit Card</h5> 
                                </div>
                            </div>
                            {
                                option === 'mobile' ? 
                                <MobilePaymentWapper courseName={courseName} amount={amount} setAmountError={setError} />
                                : 
                                <CardPaymentWrapper amount={amount} setAmountError={setError} />
                            }

                        </div>
                    </div>
                    <div className="col-md-6 summary-wrapper">
                        <h3 className='text-blue mb-4'>Summary</h3> 
                        <select name="courseName" id="courseName" className='w-100 p-3 mb-4' onChange={ e => setCourseName(e.target.value)}>
                            <option value="None" disabled selected>Select Course Name</option>
                        {
                                courses.map( course => <option value={course}>{course}</option>)
                        }
                        </select>
                        <div className="d-sm-flex justify-content-between">
                          <p className='fw-bold mb-0'>Amount to pay now :</p>
                          <div className="d-flex align-items-center mt-3 mt-sm-0">
                            <p className=' mb-0 me-2'>PKR</p>
                            <input type="number" value={amount} onChange={ e => setAmount(e.target.value) } className='border-0 border-bottom'  placeholder='Amount' />
                          </div>
                        </div>
                          {error && <p className='text-danger fw-bold mt-3'>{error}</p>}
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer/>
    </div>
  )
}

