import React from 'react'
import './Terms.css'

export default function Terms() {
  return (
    <div className='section-spacing terms-page'>
        <div className="container pt-4">
            <div className="section-heading">
                <h1>Terms and Conditions</h1>
            </div>

            {/* <main className="terms-and-conditions-content">
                <h3>1. <b>Information We Collect:</b></h3>
                <ul>
                    <li>To enhance your Transformatics experience, we collect and store basic account information, including your name, email address, and profile details.</li>
                </ul>
                <h3 className='mt-5'>2. <b>External Links:</b></h3>
                <ul>
                    <li>Transformatics may contain links to third-party websites or services. This Privacy Policy does not cover the privacy practices of these external entities. Please review their respective privacy policies.</li>
                </ul>
                <h3 className='mt-5'>3. <b>Consent and Changes:</b></h3>
                <ul>
                    <li>By using the Transformatics application, you consent to the terms outlined in this Privacy Policy.
                        <br />Transformatics may update this Privacy Policy periodically. Users will be notified of any significant changes.</li>
                </ul>
                <h3 className='mt-5'>4. <b>Contact Us:</b></h3>
                <ul>
                    <li>For any questions, concerns, or requests regarding your privacy, please contact us at <a href="mailto:Info@transformatics.pk">Info@transformatics.pk</a>  . Your trust is of utmost importance to us, and we are committed to providing you with a secure and enjoyable experience.</li>
                </ul>
            </main> */}

            <main className="terms-and-conditions-content">
                <p>
                These terms and conditions apply to Services provided by Transformatics Pvt Ltd (“Transformatics Pvt Ltd” or “we” or “us”). 
                You may contact us on info@transformatics.pk and/or +92 309 9077000.
                These terms and conditions apply to the sale of any Online Course and/or On-premise Course and/or Hybrid Course.  Please read these terms and conditions carefully before purchasing any Course.
                For purchases via our website, by registering you agree to the terms of this agreement which will bind you.  If you do not agree to these terms and conditions you must cease to continue to purchase any Services from us. 
                </p>

                <h4 className='mt-4'>1. Definitions</h4>
                <b>“Confidential Information” </b> <span>means information provided by one party to the other in written, graphic, recorded, machine readable or other form concerning the business, clients, suppliers, finances and other areas of the other party’s business or products, including, without limitation, the Course Materials, but does not include information in the public domain other than through the default of the party disclosing the information, information required to be disclosed by any court or regulatory authority, or any information already in the possession or control of the disclosing party.</span>

                <br />
                <b>“Course Materials”  </b> <span>means the information provided by Transformatics Pvt Ltd to accompany a course provided as part of the Services in hard copy or electronic form.</span>

                <br />
                <b>“Fees” </b> <span> means the fees paid by you to Transformatics Pvt Ltd for the Services.</span>

                <br />
                <b>“Intellectual Property Rights”</b> <span> means copyright, rights in or relating to databases, patent rights, performers’ rights, designs and registered designs, trademarks, rights in or relating to Confidential Information and other intellectual property rights (registered or unregistered) throughout the world.</span>

                <br />
                <b>“Online Course”</b> <span>  means the delivery by us of an online course pursuant to which you learn course materials remotely.</span>

                <br />
                <b>“Services” </b> <span> means the provision of the Online Course and/or On-premise Course and/or Hybrid Course and/or the Course Materials together with such other services as agreed from time to time and purchased by you through the Website or by telephone.</span>

                <br />
                <b>“On-premise Course” </b> <span>  means a course taught by us in a classroom setting to which you attend in person.</span>

                <br />
                <b> “Website”</b> <span>  means https://transformatics.pk/, https://www.transformatics.pk/ https://transformatics.pk/terms-and-conditions/ </span>


                <h4 className='mt-4'>2. Services</h4>

                <p>2.1. A description of the Services is available on our Website.  We will provide the Services with care and skill in accordance with the description set out on the Website. <br />
                2.2. We reserve the right to vary or withdraw any of the Services described on the Website without notice. <br />
                2.3 We expect you to confirm that the Services you are purchasing will meet your needs.  We do not make any guarantee to you that you will obtain a particular result, professional qualification or employment opportunity from your purchase and completion of any of the Services.
                </p>


                <h4 className='mt-4'>3. Ordering Services</h4>
                <br />
                <b>Purchasing Services via the Website</b>
                <br /> <br />
                <p>3.1. In order to purchase any of the Services online you must register yourself with us via the Website.  If you already have registered for purchase of any Service before, you must register again or email info@transformatics.pk letting us know regarding the purchase or contact us via telephone or WhatsApp on +92 309 9077000.</p>

                <br />
                <b>Purchasing Services via the Telephone</b>
                <br /><br />
                <p>3.2. To purchase a Service over the telephone please call +92 309 9077000. You will need to have registered yourself with us to purchase any of the Services over the telephone. <br />
                3.3. When you purchase a Service via the Website, or telephone you are offering to purchase the Services on these terms and conditions. Transformatics Pvt Ltd reserves the right to cancel or decline your order or any part of your order at any time until it has been confirmed in accordance with clause 3.5 below.
                3.4. Following receipt by us of your order for Services via the Website or on the telephone we will contact you confirming receipt of your order. <br />
                3.5. A legally binding agreement between us and you shall come into existence when we have: <br />
                (a)	Accepted your offer to purchase Services from us by sending you an email confirming the purchase; and
                (b)	Received payment of the relevant Fees from you in accordance with clause 5 below.  <br /> 
                3.6. Where your order consists of multiple Online Courses or multiple On-premise Courses, each individual course will be treated by us as a separate offer to purchase.  Acceptance of your offer to buy one or more courses will not be acceptance by us of your offer to purchase any other courses which make up your order.
                </p>


                <h4 className='mt-4'>4. Cancellation and Variation</h4>
                
                <p>4.1. Subject to clause 4.2 below, where we have accepted / confirmed the Services being purchased by you and formed a legally binding agreement with you in accordance with clause 3.5 above, then you are permitted within 7 working days starting on the day after the date we have concluded our agreement in accordance with clause 3.4, to cancel your purchase of the Services. <br />
                4.2. If you have purchased an Online Course and have already started that Online Course then you shall have no right to cancel your order. <br />
                4.3. Notwithstanding clause 4.1 there is no other right to cancel or vary your purchase of Services and any other cancellation and / or variation of course dates will be at the entire discretion of Transformatics Pvt Ltd.
                </p>

                <h4 className='mt-4'>5. Fees</h4>
                <p>5.1. The Fees for the Services shall be as set out on the Website or as told to you over the telephone at the time you placed an order for them. <br />
                5.2. Unless otherwise specified at the time you purchase the Services the Fees are exclusive of VAT or other local taxes.  Each of these costs will be set out in the Website or told to you over the telephone prior to your purchase the Services. <br />
                5.3. Fees for the Service selected by you on the Website or purchased over the telephone shall be debited from your credit / debit card at the time of purchase. Purchase can also be done through JazzCash.  Fees must be paid in full prior to you attending any Course or in Installments where half fees is to be paid prior to start of the course. <br />
                5.5. Any fees charged by your debit or credit card provider in connection with your purchase of Services are for your own account and Transformatics Pvt Ltd shall not be responsible for these. <br />
                5.6. You shall be responsible  for all costs you incur in connection with your attendance at any On-premise Courses. </p>

                <h4 className='mt-4'>6. Refund Policy</h4>
                <p>If the students who register for a training course change their mind for any reason or cannot attend the course, can avail the following refund policy: <br />
                Refunds for our courses are only given under the following circumstances: <br />
                6.1. The student did not participate in any class of the course AND the student requests a refund, in writing via email within three business days from the date of the registration (email notification sent). <br />
                6.2. There will be no refunds for any courses once a course has been accessed in any manner. <br />
                6.3. The student wishes to skip the iteration of the course, in which case they can start the course in the next iteration. <br />
                Please understand that with the enrollment of your course, you have read and agree to the aforementioned refund policy. <br />
                If the student does not complete the course/does not complete the requirements to get a certificate, they will not get any refund. Such students will be given half price for the next iteration if they wish to retake the course. 
                </p>

                <h4 className='mt-4'>7. Liability</h4>
                <p>
                    7.1. No part of the provision of the Services shall be deemed to be, nor is it intended to be, nor should it be taken to be, the provision of investment advice. <br />
                    7.2. Although Transformatics Pvt Ltd aims to provide the Services to the highest standards of the industry, neither it, nor its trainers accept any liability for (i) any inaccuracy or misleading information provided in the programs or Course Materials and any reliance by Client on any such information, (ii) any loss or corruption of data, (iii) any loss of profit, revenue or goodwill, or (iv) any indirect, special or consequential loss arising from any breach of the terms of this Agreement. <br />
                    7.3. Except to the extent that they are expressly set out in these terms and conditions, no conditions, warranties or other terms shall apply to the Services.  Subject to clause 6.5 no implied conditions, warranties or other terms apply (including any implied terms as to satisfactory quality, fitness for purpose or conformance with description). <br />
                    7.4. Subject to clause 6.5 below, Transformatics Pvt Ltd’s total liability arising from or in connection with these terms and conditions and in relation to anything which we may have done or not done in connection with these terms and conditions and the delivery of the Service (and whether the liability arises because of breach of contract, negligence or for any other reason) shall be limited to the Fees received by us in connection with the relevant Online Course or On-premise Course in relation to which a dispute has arisen. <br />
                    7.5. Nothing in this Agreement shall exclude or limit Transformatics Pvt Ltd’s liability for (i) death or personal injury caused by negligence, (ii) fraudulent misrepresentation or (iii) any other matter which under law may not be limited or excluded. <br />
                    7.6. No claim may be brought more than six months after the last date on which the Services concerned have finished or ceased to be provided by us.

                </p>

                <h4 className='mt-4'>8. Intellectual Property</h4>
                <p>
                    8.1. All Intellectual Property Rights in the Course Materials, Online Courses and the lectures given by trainers at the On-premise Courses are, and remain, the intellectual property of Transformatics Pvt Ltd or its licensors, whether adapted, written for or customized for the Client or not. <br />
                    8.2. You are not authorized to:-
                    (i) copy, modify, reproduce, re-publish, sub-license, sell, upload, broadcast, post, transmit or distribute any of the Course Materials without prior written permission; <br />
                    (ii) record on video or audio tape, relay by videophone or other means the Online Course or On-premise Course given <br />
                    (iii) use the Course Materials in the provision of any other course or training whether given by us or any third-party trainer; <br />
                    (iv) remove any copyright or other notice of Transformatics Pvt Ltd on the Course Materials; <br />
                    (v) modify, adapt, merge, translate, disassemble, decompile, reverse engineer (save to the extent permitted by law) any software forming part of the Online Courses. <br />
                    Breach by you of this clause 7.2 shall allow us to immediately terminate these terms and conditions with you and cease to provide you with any Services, including but not limited to access to the Online Courses. <br />
                    8.3. In consideration of the Fees paid by you, we grant to you a limited, non-transferable, non-exclusive license to use the Course Materials and the software in respect of the Online Course for the sole purpose of completing the Online Course and / or attending the On-premise Course.
                </p>

                <h4 className='mt-4'>9. Confidentiality</h4>
                <p>
                    9.1. Each party shall keep the other party’s Confidential Information strictly confidential and not use it otherwise than for the purposes of these terms and conditions, and shall return it on demand and not retain copies of it. <br />
                    9.2. Either party may disclose Confidential Information to its legal and other advisors for the purposes of obtaining advice from them. <br />
                    9.3. This clause shall continue notwithstanding termination of these terms and conditions. <br />
                </p>


                <h4 className='mt-4'>10. Termination</h4>
                <p>
                    10.1. We shall be entitled to terminate these terms and conditions and cease to provide you with any Services with immediate effect in the event that you: <br />
                    a.	fail to pay when due your Fees; <br />
                    b.	act in an aggressive, bullying, offensive, threatening or harassing manner towards any employee of Transformatics Pvt Ltd, any teacher or lecturer who provides the Courses or any student who attends any Course; <br />
                    c.	cheat or plagiarize any work which you are required to prepare or submit in connection with the Services or during any examination taken in connection with the Services; <br />
                    d.	steal or act in fraudulent or deceitful manner towards us or our employees or any other students who may be on our premises or attending our Courses; <br />
                    e.	intentionally or recklessly damage our property or the property of our employees or other students attending our premises; <br />
                    f.	are intoxicated through alcohol or illegal drugs while on our premises; <br />
                    g.	commit any criminal offence committed on our premises or where the victim is our employee or student; <br />
                    h.	are in breach of these terms and conditions. <br /> 
                    10.2. On termination clause 6 (liability), 7 (intellectual property rights), 8 (confidentiality) and 10 (restrictions) shall continue notwithstanding such termination.

                </p>

                <h4 className='mt-4'>11. Restrictions</h4>
                <p>
                    Any Services provided by us under these terms and conditions are personal to you and cannot be transferred or assigned to any other person.
                    We shall be entitled to assign these terms and conditions to any other company without prior notice to you.
                </p>

                <h4 className='mt-4'> 12.  Assignment</h4>
               <p>
                We may assign, transfer, sub-contract any of our rights or obligations to any third party at our discretion.
               </p>

               <h4 className='mt-4'> 13. Data Protection and Privacy Policy</h4>
               <p>

                13.1 The nature of the Services provided by us means that we will obtain, use and disclose (together "Use") certain information about you ("Data"). This statement sets out the principles governing our Use of Data. By purchasing the Services you agree to this Use. <br />
                13.2 When you register with us you will need to provide certain Data such as your contact details and demographic information. We will store this Data and use it to contact you, provide you with details of the Services you have purchased and otherwise as required during the normal provision of the course. <br />
                13.3 We may also use the above Data, and similar Data you provide us in response to surveys and provide you with communications. We will not pass any personal data onto anyone outside of Transformatics Pvt Ltd. <br />
                13.3 To enable us to monitor and improve our Services, we gather certain aggregated information about you, including details of your operating system, browser version, domain name and IP address, the URL you came from and go to and the parts of the Website you visit. <br />
                13.4. We use your information to enable us to identify whether you are using our services, assist with the provision of services and to ensure that you have access to relevant products. We will only read cookies from your cookie file placed there through your web browser's interaction with the Website. <br />
                13.5. Transformatics Pvt Ltd endeavors to take all reasonable steps to protect your personal Data including the use of encryption technology, but cannot guarantee the security of any Data you disclose. You accept the inherent security implications of being and transacting on-line over the internet and will not hold us responsible for any breach of security. <br />
                13.6. If you wish to change or update the data we hold about you, please e-mail info@transformatics.pk or contact us +92 309 9077000.
               </p>

               <h4 className='mt-4'> 14. Law and Jurisdiction</h4>
               <p>
                This Agreement is subject to Pakistani law and the parties submit to the exclusive jurisdiction of the Pakistani courts in connection with any dispute hereunder.
               </p>

               <h4 className='mt-4'> 15. Notices</h4>
               <p>
                You can contact us by any of the following methods: <br />
                Email: info@transformatics.pk <br />
                Post: Plot No 395, 396, Fazal Software Technology Park, Khayaban-e-Johar, Sector I-9/3 I-9, Islamabad, Islamabad Capital Territory 44000 <br />
                Telephone/WhatsApp: +92 309 9077000. <br />
               </p>


               



            </main>

        </div>
    </div>
  )
}
