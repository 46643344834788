import React , { useState , useEffect , useRef } from 'react'
import { Link } from 'react-router-dom'
export default function Header() {

    let [ showMenu , setShowMenu ] = useState(false)
    let headerRef = useRef(null)
    let buttonRef = useRef(null)

    let handleToggle = () => {
        setShowMenu( state => !state )
    }

    useEffect(() => {
        const handleClickOutside = (event) => {

        if ( buttonRef.current.contains(event.target) )
            return 
        else if ( showMenu && !headerRef.current.contains(event.target))
            setShowMenu( false )

        }
    
        window.addEventListener('click', handleClickOutside);
    
        return () => {
          window.removeEventListener('click', handleClickOutside);
        };
      }, [showMenu]);


  return (
    <>
        <header className="header header-default  " id="header">
            <div className="header-inner container flex-between ">
            <div className="logo-wrapper">
                <Link to="/">
                <img
                    src= { "/images/logo-colored.png" }
                    width="170px"
                    alt="transformatics logo"
                    className="logo"
                />
                </Link>
            </div>
            <nav className="navbar d-none d-md-flex">
                <Link to="/">Home</Link>
                <Link to="/about-us">About Us</Link>
                {/* <Link to="/trainings">Trainings</Link> */}
                <div className="drop-down-wrapper">
                    <Link to={'/'}>Services</Link>
                 <ul className="drop-down-menu">
                    <li>
                    {" "}
                    <Link to="/trainings">Trainings</Link>{" "}
                    </li>
                    <li>
                    {" "}
                    <Link to="/consultancy">Consultancy</Link>{" "}
                    </li>
                    <li>
                    {" "}
                    <Link to="/counselling">
                        Career Counselling
                    </Link>{" "}
                    </li>
    
                </ul> 
                </div>
                {/* <Link to="/consultancy">Consultancy</Link> */}
                <Link to="/instructors">Experts</Link>
                <Link to="/contact-us">Contact Us</Link>
            </nav>
            <button
                className="d-flex flex-center d-md-none toggle-menu"
                id="openMobileHeader"
                onClick={handleToggle}
                ref={buttonRef}
                aria-label='Toggle navigation'
                aria-controls="mobileMenu"
            >
                <img src="/images/menu.png" width="17px" alt="menu icon" />
            </button>
            </div>
        </header>

        <header className={`header-mobile ${ showMenu ? "header-mobile-show" : "header-mobile-hide" } `} 
        id="mobileHeader"
        ref={headerRef}
        >
            <div className="mobile-logo-wrapper flex-between">
            <Link to="/">
                <img
                src="/images/logo-colored.png"
                className="mb-0"
                width="160px"
                alt="transformatics logo"
                />
            </Link>
            <button className="btn-none flex-center p-1" id="closeMobileHeader" onClick={handleToggle}
                aria-controls="mobileMenu">
                <img
                src="/images/cancel.png"
                className="mb-0"
                width="22px"
                alt="cancel button"
                />
            </button>
            </div>
            <nav className="header-mobile-nav pt-4">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item ">
                <h2 className="accordion-header" id="headingOne">
                    <Link className="accordion-button collapsed" to="/" onClick={handleToggle}>
                    Home
                    </Link>
                </h2>
                <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <Link className="accordion-button collapsed" to="/about-us" onClick={handleToggle}>
                    About Us
                    </Link>
                </h2>
                <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                    {/* <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    >
                    Trainings
                    </button> */}
                    <Link
                    className="accordion-button collapsed"
                    to="/trainings"
                    onClick={handleToggle}
                    >
                    Trainings
                    </Link>
                </h2>
                {/* <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body">
                    <ul className="ps-4">
                        <li className="mb-3">
                        <Link to="/trainings/big-data" onClick={handleToggle}>Big Data</Link>
                        </li>
                        <li className="my-3">
                        <Link to="/trainings/data-science" onClick={handleToggle}>Data Science</Link>
                        </li>
                        <li className="my-3">
                        <Link to="/trainings/dwh-bi" onClick={handleToggle}>
                            Data WareHouse and BI
                        </Link>
                        </li>
                        <li className="my-3">
                        <Link to="/trainings/business-intelligence" onClick={handleToggle}>
                            Business Intelligence
                        </Link>
                        </li>
                    </ul>
                    </div>
                </div> */}
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                    <Link
                    className="accordion-button collapsed"
                    to="/consultancy"
                    onClick={handleToggle}
                    >
                    Consultancy
                    </Link>
                </h2>
                <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>  
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                    <Link
                    className="accordion-button collapsed"
                    to="/counselling"
                    onClick={handleToggle}
                    >
                    Career Counselling
                    </Link>
                </h2>
                <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                    <Link
                    className="accordion-button collapsed"
                    to="/instructors"
                    onClick={handleToggle}
                    >
                    Experts
                    </Link>
                </h2>
                <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                    <Link
                    className="accordion-button collapsed"
                    to="/contact-us"
                    onClick={handleToggle}
                    >
                    Contact Us
                    </Link>
                </h2>
                <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body"></div>
                </div>
                </div>
            </div>
            </nav>
        </header>
    </>
  )
}
