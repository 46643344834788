import React from 'react'
import './PricePlan.css'
import { Link , useNavigate, useParams } from 'react-router-dom'
import trainings from '../../variables/trainings'

export default function PricePlan() {

    const { slug } = useParams()
    const nav = useNavigate()
    const course = trainings.find( training => training.slug === slug )

    if ( !course )
    {
        return <div className='my-5 py-5 '><h1 className='my-5 py-5 text-center'>Page Not Found</h1> </div>
    }

    const { fee : individualPrice , name , formLink} = course 


    const groupOfThreePrice = individualPrice * 0.90 // 10% discount
    const groupOfFivePrice = individualPrice * 0.85 // 15% discount

  return (
    <div className="site-wrapper price-plan-page">
        <section className="price-grid-wrapper">
            <div className="container">
                <div className="section-heading text-center mb-5">
                    <h6>Price Plan</h6>
                    <h1>{name}</h1>
                </div>
                <div className="price-grid">
                <div className="price-card">
                    <div className="price-card-header">
                    <h5>Individual</h5>
                    <h1>{individualPrice.toLocaleString()} PKR</h1>
                    <p>Per person</p>
                    </div>
                    <ul className="price-card-feature-list">
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Led by industry professionals</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Hybrid Mode (online and offline)</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Free Rejoin</span>
                    </li>
                    </ul>
                    {/* <a href={formLink} className="generic-btn-1 mt-5 mx-auto">
                    Book a Seat
                    </a> */}
                    <Link className="generic-btn-1 mt-5 mx-auto" to={`/form/${slug}/one`}>
                        Book a Seat
                    </Link>
                </div>
                <div className="price-card">
                    <div className="price-card-header">
                    <h5>Group Of Five</h5>
                    <h1>{groupOfFivePrice.toLocaleString()} PKR</h1>
                    <p>Per person</p>
                    </div>
                    <ul className="price-card-feature-list">
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Led by industry professionals</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Hybrid Mode (online and offline)</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Free Rejoin</span>
                    </li>
                    </ul>
                    {/* <a href={formLink} className="generic-btn-1 mt-5 mx-auto" >
                    Book a Seat
                    </a> */}
                    <Link className="generic-btn-1 mt-5 mx-auto" to={`/form/${slug}/five`}>
                        Book a Seat
                    </Link>
                </div>
                <div className="price-card">
                    <div className="price-card-header">
                    <h5>Group Of Three</h5>
                    <h1>{groupOfThreePrice.toLocaleString()} PKR</h1>
                    <p>Per person</p>
                    </div>
                    <ul className="price-card-feature-list">
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Led by industry professionals</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Hybrid Mode (online and offline)</span>
                    </li>
                    <li>
                        <img src="/images/tick.png" width="20px" alt="" />{" "}
                        <span>Free Rejoin</span>
                    </li>
                    </ul>
                    {/* <a href={formLink} className="generic-btn-1 mt-5 mx-auto">
                    Book a Seat
                    </a> */}
                    <Link className="generic-btn-1 mt-5 mx-auto" to={`/form/${slug}/three`}>
                        Book a Seat
                    </Link>
                </div>
                </div>
            </div>
        </section>

    </div>
  )
}
