import React , {useState} from 'react'
import axios from '../../axios'

export default function VerifyPayment() {

    const [isLoading , setIsLoading] = useState(false)
    const [transactionDetails , setTransactionDetails] = useState({})
    const [transaction_no , setTransaction_no] = useState("")

    const handleSubmit = async () => {
        
        try{
            setIsLoading(true)
            let { data } = await axios.post("/payment/verify" , { "pp_TxnRefNo" : transaction_no } )
            const details = data.data
            setTransactionDetails( details )
            console.log(details)
        }
        catch(err)
        {
            console.log(err)
        }
        finally{
            setIsLoading(false)
        }
    }

  return (
    <div className='verification-page bg-light'>
        <div className="container section-spacing">
            <img src="./images/logo-colored.png" className='mt-5' width={'200px'} alt="" />
            <div className='mt-5'>
                <h4 htmlFor="">Enter Transaction Number</h4>
                <input type="text" value={transaction_no} onChange={ e => setTransaction_no(e.target.value)}  className='w-50 p-2' />
                <button disabled={isLoading} onClick={handleSubmit} className="generic-btn-1 d-inline-flex py-2 ms-4">Verify</button>
            </div>
            {
                transactionDetails.pp_PaymentResponseMessage ? 
                <h4 className='my-5'>  Payment Status : <span className='text-blue'> {transactionDetails.pp_PaymentResponseMessage} </span> </h4> 
                :
                <></>
            }
            {
                transactionDetails.pp_ResponseCode === '110' ? 
                <p className="text-danger mt-2">{transactionDetails.pp_ResponseMessage}</p>
                :
                <></>
            }
        </div>
    </div>
  )
}
