import React from 'react'

export default function CardSuccess(params) {

    console.log(params)

    return (
        <div className='success-page'>
            <div className="container section-spacing px-3">
                <img src="./images/logo-white.png" className='mb-5' width={'200px'} alt="" />
                <h1 className='fw-bold text-center'>Transaction Complete ! <br />Your Seat has been Reserved</h1>
            </div>
        </div>
        )
}
