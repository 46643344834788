import React from 'react'
import './Success.css'
import { Link , useLocation } from 'react-router-dom'
export default function Success() {

  const {state} = useLocation()
  console.log(state)
  return (
    <div className='success-page'>
        <div className="container section-spacing px-3">
          <img src="./images/logo-white.png" className='mb-5' width={'200px'} alt="" />
          <h1 >Payment Request Sent !</h1>
          <h2 className='my-4 fw-bold'>Check your JazzCash App to approve the pending payment</h2>
          <h3> Your Transaction No. is : {state?.transaction_no} </h3>
          <h5>(Save this for future use)</h5> <br />  
          <Link className=' p-3 rounded-2 d-inline-block bg-white text-blue mt-4' to={'/verify-payment'} >Verify Payment</Link>
        </div>
    </div>
  )
}
