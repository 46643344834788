import React from 'react'
import { Link } from 'react-router-dom'
import info from "../../images/info.png"

export default function Footer() {
  return (
    <footer className="footer">
        <div className="footer-inner container ">
            <div className="footer-shape-1">
                <img src="/images/shape-21-01.png" width="160px" alt="" />
            </div>
            <div className="footer-shape-2">
                <img src="/images/shape-35.png" width="120px" alt="" />
            </div>
            <div className="row footer-grid">
                <div className=" footer-col col-lg-4 col-md-6 col-sm-12 col-12 pt-4">
                <h5 className="mb-4 fw-bold">Find Us</h5>
                <p className="my-4 text-white fw-semibold pe-5">
                    We provide Trainings, Career Guidance &amp; Consultancy in
                    Technology &amp; Non Technology Domains. With 1,000+ professional
                    trained and over 65% Job placement ratio, we are transforming
                    Pakistan's IT Landscape
                </p>
                <div className="footer-social-links d-flex align-items-center gap-3">
                    <a
                    href="https://www.facebook.com/transformatics.pk/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <img src="/images/fb.png" width="23px" alt="facebook" />
                    </a>
                    <a
                    href="https://www.linkedin.com/company/transformatics/?originalSubdomain=pk"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <img src="/images/linkedin.png" width="23px" alt="linkedin" />
                    </a>
                    <a
                    href="https://www.youtube.com/@transformaticspk"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <img src="/images/youtube.png" width="23px" alt="youtube" />
                    </a>
                    <a
                    href="https://www.instagram.com/transformatics.pk/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <img src="/images/instagram.png" width="23px" alt="instagram" />
                    </a>
                    <a
                    href="https://twitter.com/_transformatics"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <img src="/images/twitter.png" width="22px" alt="twitter" />
                    </a>
                </div>
                </div>
                <div className="footer-col col-lg-2 col-md-6 col-sm-12 col-12 pt-4">
                <h5 className="mb-4 fw-bold">Explore</h5>
                <ul className="pt-2">
                    <li className="mt-0">
                    <Link to="/about-us">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>About Us</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/instructors">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Instructors</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/trainings">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Courses</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/contact-us">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Contact Us</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/consultancy">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Consultancy</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/terms-and-conditions">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Policy</span>
                    </Link>
                    </li>
                    <li className='d-none'>
                    <Link to="/card/success">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Success</span>
                    </Link>
                    </li>
                </ul>
                </div>
                <div className="footer-col col-lg-3 col-md-6 col-sm-12 col-12 pt-4">
                <h5 className="mb-4 fw-bold">Top Trainings</h5>
                <ul className="pt-2">
                    <li className="mt-0">
                    <Link to="/trainings/big-data">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Big Data</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/trainings/data-science">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Data Science</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/trainings/business-intelligence">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>Business Intelligence</span>
                    </Link>
                    </li>
                    <li>
                    <Link to="/trainings/dwh-bi">
                        <img
                        src="/images/double-arrow.png"
                        width="17px"
                        alt="->"
                        />
                        <span>DWH &amp; Business Intelligence</span>
                    </Link>
                    </li>
                </ul>
                </div>
                <div className="footer-col col-lg-3 col-md-6 col-sm-12 col-12 pt-4">
                <h5 className="mb-4 fw-bold">Contact Info</h5>
                <ul className="pt-2">
                    <li className="mt-0">
                    <a href="https://www.facebook.com/transformatics.pk/">
                        <img src="/images/location.png" width="17px" alt="location pin" />
                        <span>Islamabad, Pakistan</span>
                    </a>
                    </li>
                    <li>
                    <a href="mailto:Info@transformatics.pk">
                        <img src="/images/email.png" width="17px" alt="email" />
                        <span>Info@transformatics.pk</span>
                    </a>
                    </li>
                    <li>
                    <a href="tel:92 309 9077000">
                        <img
                        src="/images/phone-white.png"
                        width="17px"
                        alt="phone"
                        />
                        <span>+92 309 9077000</span>
                    </a>
                    </li>
                </ul>
                </div>
            </div>
            <Link to={'/recurring-payment'} className="text-decoration-underline text-white mt-5 d-block" 
            style={{fontSize : '14px' }}>
                <img src={info} width={'26px'} className='me-3' alt="" />
                <>
                    Already Registered? Proceed directly to payment 
                </>
            </Link>
        </div>
    </footer>

  )
}
