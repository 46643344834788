import React ,{useEffect}from 'react'
import './Home.css'
import Slider from "react-slick";
import chevronLeft from './../../images/chevron-left.png'
import chevronRight from './../../images/chevron-right.png'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactBanner from '../../components/ContactBanner/ContactBanner';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';


export default function Home() {

    const [viewPortEntered, setViewPortEntered] = React.useState(false)

    let partnerShipSettings = {
        autoplay : true ,
        arrows : true ,
        responsive : true ,
        speed : 200 ,
        slidesToShow : 5 ,
        slidesToScroll : 2,
        swipeToSlide : true ,
        nextArrow : <button type="button" className="slick-next"><img src = {chevronRight} alt='next arrow' /></button> ,
        prevArrow : <button type="button" className="slick-prev"><img src = {chevronLeft} alt='prev arrow' /></button> ,
        responsive: [{
      
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            }
      
          }, {
      
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            }
      
          }, {
      
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
      
          }, {
      
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            }
      
        }]
    }

    let testimonialSettings = {
        autoplay : true ,
        arrows : false ,
        responsive : true ,
        swipe : false ,
        slidesToShow : 2 , 
        dots : true , 
        settings : {
          pauseOnHover : true 
        } ,
        responsive: [
        {
  
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  
        },
  
        {
  
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
  
        }]
    }


    useEffect( () => {
        AOS.init({ once : true });
    } , [] )

  return (
   
    <div className="site-wrapper home-page">

    <section className="banner-area py-0 mb-5"
        data-bs-touch="true"
        data-aos="fade-in"
    >
        <div id="carouselBanner" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner ">
            <div className="carousel-item active " data-bs-interval={3000}>
            <div
                className="carousel-item-content "

            >
                <div className="carousel-item-content-inner">
                <div className="container">
                    <h1 className="text-white f-light">Transforming Business Landscape</h1>
                    <h1 className="text-white fw-bolder">
                    through Data and Analytics
                    </h1>
                </div>
                </div>
            </div>
            </div>
            <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselBanner"
            data-bs-slide="prev"
            >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
            </button>
            <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselBanner"
            data-bs-slide="next"
            >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
            </button>
        </div>
        </div>
    </section>

    <section className="services section-spacing pt-5">
        <div className=" services-inner container">
        <div className="section-heading text-center">
            <h6>Service Categories</h6>
            <h1>What We Offer</h1>
        </div>
        <div className="services-grid  mt-5">
            <div
            className="service-card "
            data-aos="fade-in"
            data-aos-duration={800}
            >
            <div className="service-card-inner">
                <img src="./images/trainings.avif" width="150px" alt="training icon" />
                <h4 className="fw-bold mt-3 text-purple mt-4">Trainings</h4>
                <p>
                We offer best in class trainings both in Technical &amp; Non
                Technical Domains for individuals as well as corporates
                </p>
                <Link
                className="generic-btn-1 mt-4 mx-auto w-50"
                to="/trainings"
                >
                Explore
                </Link>
            </div>
            </div>
            <div
            className="service-card "
            data-aos="fade-in"
            data-aos-duration={800}
            >
            <div className="service-card-inner">
                <img src="./images/consultancy.png" width="150px" alt="consultancy icon" />
                <h4 className="fw-bold mt-3 text-purple mt-4">Career Support</h4>
                <p>
                We offer a range of career support from Interview Prep to resume
                building to support in finding the right job
                </p>
                <Link
                className="generic-btn-1 mt-4 mx-auto w-50"
                to="/counselling"
                >
                Explore
                </Link>
            </div>
            </div>
            <div
            className="service-card "
            data-aos="fade-in"
            data-aos-duration={800}
            >
            <div className="service-card-inner">
                <img src="./images/career.png" width="150px" alt="career icon" />
                <h4 className="fw-bold mt-3 text-purple mt-4">Consultancy</h4>
                <p>
                {" "}
                We offer services to help organizations leverage technology for
                efficiency, productivity, and profitability.
                </p>
                <Link
                className="generic-btn-1 mt-4 mx-auto w-50"
                to="/consultancy"
                >
                Explore
                </Link>
            </div>
            </div>
        </div>
        </div>
    </section>

    <section className="partnership-showcase section-spacing "
        style={{ paddingBottom: 180 }}
    >
        <div className="partnership-showcase-inner container">
            <div className="section-heading " style={{ position: "relative" }}>
                <h6 className="text-white">Our Partnerships</h6>
                <h1 className="text-white">Corporate Partnerships</h1>
                <div className="partnership-showcase-shape-1">
                <img src="./images/shape-05-02.png" width="40px" alt="shape" />
                </div>
                <div className="partnership-showcase-shape-2">
                <img src="./images/shape-02-03.png" width="40px" alt="shape" />
                </div>
            </div>
            <div className="partner-showcase-content">
                <Slider {...partnerShipSettings}>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-1.png" width="220px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-10.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-2.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-9.png" width="150px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-8.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-7.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-6.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-5.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-4.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-3.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-11.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-12.png" width="200px" alt="partner" />
                    </div>
                    <div className="partner-showcase-item">
                    <img src="./images/partner-13.png" width="170px" alt="partner" />
                    </div>
                </Slider>
            </div>
        </div>
        <div className="partnership-showcase-shape-3">
            <img src="./images/shape-21.png" width="40px" alt="shape" />
        </div>
    </section>


    <section className="testimonials section-spacing position-relative" style={{"paddingBottom":"150px"}}>
        <div className="container ">
        <div className="row">
            <div className="col-12">
            <div className="section-heading ">
                <h6>Testimonials</h6>
                <h1>What People have to Say</h1>
            </div>
            <div className="testimonial-desc-slider pt-5">
                <Slider {...testimonialSettings} >
                    <div className="desc-slider-item">
                        <h5 className="text-gray mb-4" style={{ lineHeight: "1.6" }}>
                            I have been working with the founder and trainer of
                            Transformatics for many years. He has provided many trained
                            resources of data analytics to Teradata. I wish him good luck
                            for his mission.
                        </h5>
                        <h5 className="text-purple fw-bold">Bilal Bin Munir</h5>
                        <p className="text-blue">
                            Senior Practice lead MS, Teradata Corporation Pakistan
                        </p>
                        <div className="slider-nav-item ">
                            <div>
                            <img src="./images/bilal-bin-munir.avif" alt="bilal bin munir" />
                            </div>
                        </div>
                    </div>
                    <div className="desc-slider-item">
                        <h5 className="text-gray mb-4" style={{ lineHeight: "1.6" }}>
                            I have worked with the founder on many analytics projects and I
                            must say, the results delivered were of exceptional quality. I
                            have great respect for him as a colleague and a friend.
                        </h5>
                        <h5 className="text-purple fw-bold">Umer Iftikhar</h5>
                        <p className="text-blue">
                            Head of contract management, Scottish Power Renewables
                        </p>
                        <div className="slider-nav-item ">
                            <div>
                            <img src="./images/umer.jpeg" alt="umer" />
                            </div>
                        </div>
                    </div>
                    <div className="desc-slider-item">
                        <h5 className="text-gray mb-4" style={{ lineHeight: "1.6" }}>
                            The founder of Transformatics is the guru of data analytics,
                            happy to see him transforming people and organizations.
                        </h5>
                        <h5 className="text-purple fw-bold">Haider Altaf Malik</h5>
                        <p className="text-blue">Head of Data Analytics, Jazz</p>
                        <div className="slider-nav-item ">
                            <div>
                            <img src="./images/haider.avif" alt="haider" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            </div>
        </div>
        </div>
    </section>

    <ContactBanner/>

    </div>
  )
}
