import React , {useState} from 'react'
import { toast } from 'react-toastify' 
import axios  from '../../axios'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const mobileWalletSchema = Yup.object().shape({
    mobileNumber : Yup.string().length(11,'Invalid Mobile Number'),
    cnic : Yup.string().length(6,'Invalid CNIC number. Enter last 6 digits'),
  })
  
  const mobileWalletInitialValues = {
    mobileNumber : '' ,
    cnic : ''
  }

const MobilePaymentWapper = ({ amount , setAmountError , courseName}) => {

    const nav = useNavigate()
    const [ isLoading , setIsLoading ] = useState(false)
    const [ error , setError ] = useState("")
  
    const handleMobileWalletSubmission = async ( values ) => {
      
      if ( amount === "" || amount <= 0 )
      {
        setAmountError( "Invalid amount")
        return
      }

      if(!courseName)
      {
        setError( "Course name not selected")
        return
      }
  
      let { mobileNumber , cnic } = values
  
      try{
  
        setIsLoading(true)
        const payload = {
          "pp_MobileNumber" : mobileNumber ,
          "pp_CNIC" : cnic ,
          "pp_Amount" : amount * 100,
          "course_Name" : courseName
        }
  
        const { data } = await axios.post( "/payment/jazzcash", payload)
        const transactionDetails = data.data
        const responseCode = parseInt(transactionDetails.pp_ResponseCode)
        console.log(data)
        if ( 
          responseCode === 0
          || (responseCode >= 121 && responseCode < 125) 
          || responseCode === 157 
          )
        {
          toast.success('Transaction Successfull. Check your jazzcash app')
          nav("/jazzcash/success" , { state : { "transaction_no" : transactionDetails.pp_TxnRefNo , "message" : transactionDetails.pp_ResponseMessage }})
        }
        else
        {
          toast.error('Transaction Failed')
          if ( transactionDetails.pp_ResponseMessage === '' )
            setError("An Error occured")
          else
            setError(transactionDetails.pp_ResponseMessage)
        }
      }
      catch(err)
      {
        console.log(err)
      }
      finally{
        setIsLoading(false)
      }
    }
  
    return(
      <Formik 
      initialValues={mobileWalletInitialValues} 
      validationSchema={mobileWalletSchema} 
      onSubmit={handleMobileWalletSubmission}
      >
       {({ errors, touched }) => (
         <Form className='payment-option-body'>
           <h6 className="fw-bold mb-0">Mobile number</h6>
           <Field type="tel" required  pattern="[0-9]{11}" name='mobileNumber' placeholder='Enter your mobile number' />
           {errors.mobileNumber && touched.mobileNumber ? (<div className='text-danger fw-bold'>{errors.mobileNumber}</div>) : null}
           <h6 className="fw-bold mt-3">Last 6 Digits of CNIC</h6>
           <Field type="tel" name='cnic' placeholder='Enter last 6-digits of your CNIC' />
           {errors.cnic && touched.cnic ? (<div className='text-danger fw-bold'>{errors.cnic}</div>) : null}
           <p className='text-muted '>By completing your purchase you agree to these Terms of Service.</p>
           {
           isLoading ? 
               <>
               <p className="text-muted fw-bold">Check your jazzcash for any pending payments</p>
               <img src='/images/loader.png' className='loader' width={'80px'} /> 
               </> :
               <>
                <p className="text-danger">{error}</p>
                <div className="d-flex flex-column flex-lg-row gap-4">
                  <button className="generic-btn-1 w-auto text-center" type='submit' disabled={isLoading}>Complete Checkout</button>
                  <Link to={'/registration/success'} className="generic-btn-1 w-auto text-center" disabled={isLoading} >Pay Later</Link>
                </div>
               </>
           }
         </Form>
       )}
     </Formik>
    )
  }

export default MobilePaymentWapper