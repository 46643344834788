import React from 'react'

export default function RegistrationSuccess() {

    return (
        <div className='success-page'>
            <div className="container section-spacing px-3">
                <img src="./images/logo-white.png" className='mb-5' width={'200px'} alt="" />
                <h1 className='fw-bold text-center'>You have Registered Successfully !</h1>
                <div className='mt-4 d-flex align-items-center justify-content-center gap-3'>
                    <img src="/images/info.png" width={'28px'} alt="" />
                    <p className='mb-0 fw-semibold'>Registration does not guarantee reservation of seat. To guarantee reservation of seat, make sure to complete the payment process</p>
              </div>
            </div>
        </div>
        )
}
