import React from 'react'
import './Course.css'
import info from "../../images/info-blue.png"
import { Link, useLocation , useNavigate } from 'react-router-dom'
import trainings from '../../variables/trainings' 
import Accordion from 'react-bootstrap/Accordion';
import {Helmet} from "react-helmet";

export default function Course() {

    const navigate = useNavigate()
    let { pathname } = useLocation()
    let slug = pathname.split('/')[2]
    console.log("path : " , slug )

    let course = trainings.find( el => el.slug === slug )
    

  return (
    <div className="site-wrapper course-page">
    <Helmet>
        <title>{course?.name}</title>
        <meta name="description" content={course.description[0]} />
    </Helmet>
    <section className="course-detail section-spacing">
        <div className="container">
        <div className="course-detail-header d-block d-lg-none mt-5">
            <h1 className="text-purple fw-bold">
            {course.name}
            </h1>
        </div>
        <div className="course-detail-content row">
            <div className="course-detail-left col-lg-8">
            <div className="course-detail-header d-none d-lg-block">
                <h1 className="text-purple fw-bold">
                {course.name}
                </h1>
            </div>
            <main className="course-detail-tab-parent mt-5">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    >
                    Overview
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    >
                    Curriculum
                    </button>
                </li>
                {
                    course.instructors.length > 0 
                    && 
                    <li className="nav-item" role="presentation">
                        <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        >
                        Instructor
                        </button>
                    </li>
                }
                </ul>
                <section className="tab-content" id="pills-tabContent">
                    <div className=" course-overview tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <h4 className="text-purple fw-bold mb-4">Course Description</h4>
                        {course.description.map( (desc,ind) => <p className='mb-3 text-justify' key={ind} >{desc} </p> )}
                        {
                            course.learningObjectives.length > 0 
                            && 
                            <h4 className="text-purple fw-bold mt-5 mb-4">
                            What You’ll Learn From This Course
                            </h4>
                        }
                        <ul className="outcome-points">
                            {
                                course.learningObjectives.map( (text,ind) => <li key={ind}>{text}</li>)
                            }
                        </ul>
                    </div>
                    <div className=" course-outline tab-pane fade  "
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <Accordion defaultActiveKey={0}>
                            {course.curriculum.map( (weekItem,ind) => <OutlineItem {...weekItem} key={ind} index={ind} /> )}
                        </Accordion>
                    </div>
                    <div className="instructor-profile-parent tab-pane fade "
                        id="pills-contact"
                        role="tabpanel"
                    >

                        {
                            course.instructors.map( (instructor) => <InstructorItem {...instructor} key={instructor.name} />)
                        }

                    </div>
                </section>
            </main>
            </div>
            <div className="course-detail-right col-lg-4 px-xl-4">
            <div className="course-detail-right-inner">
                <div className="course-video-wrapper">
                {
                    course.imgSrc ? 
                    <img src={course.imgSrc} width='100%' alt="" />
                    :
                    <div
                        style={{
                        height: 0,
                        overflow: "hidden",
                        paddingTop: "56.25%",
                        position: "relative",
                        width: "100%",
                        borderRadius: 5
                        }}
                    >

                        <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                        }}
                        src="https://tube.rvere.com/embed?v=dCBOkSqtX_c"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                        ></iframe>
                    </div>
                        
                }
                </div>
                <ul className="course-info-list">
                <li>
                    <span>
                    {" "}
                    <img
                        src="/images/calender.png"
                        width="20px"
                        alt=""
                    />{" "}
                    Duration
                    </span>
                    <span>{course.duration}</span>
                </li>
                <li>
                    <span>
                    {" "}
                    <img
                        src="/images/clock.png"
                        width="20px"
                        alt=""
                    />{" "}
                    Timings
                    </span>
                    <span> 9pm - 10pm</span>
                </li>
                <li>
                    <span>
                    {" "}
                    <img
                        src="/images/document.png"
                        width="20px"
                        alt=""
                    />{" "}
                    Hours
                    </span>
                    <span> {course.hours} </span>
                </li>
                <li>
                    <span>
                    {" "}
                    <p className="font-weight-bold mb-0" style={{fontSize:'13px' , color : "#525FE1"}}>PKR</p>{" "}
                    Fees
                    </span>
                    <span> {course.fee.toLocaleString()} </span>
                </li>
                <li>
                    <span>
                    {" "}
                    <img
                        src="/images/avatar.png"
                        width="24px"
                        alt=""
                    />{" "}
                    Instructor
                    </span>
                    <span> Faraz Shahid </span>
                </li>
                </ul>
                <Link
                to={"/price-plan/" + course.slug}
                className="generic-btn-1 mt-4"
                >
                Reserve Your Seat
                </Link>
                <Link to={'/recurring-payment'} className="text-decoration-underline text-center text-purple mt-3 d-block" 
                style={{fontSize : '14px' }}>
                    <img src={info} width={'26px'} className='me-3' alt="" />
                    <>
                        Already Registered? Proceed directly to payment 
                    </>
                </Link>
            </div>
            </div>
        </div>
        </div>
    </section>

    </div>

  )
}


function OutlineItem( { week , topics , summary , index } )
{
    return (
        <div className="course-outline-item accordion-item">
            
            
            <Accordion.Item eventKey={index} className='border-0'>
                <Accordion.Header>{week}</Accordion.Header>
                <Accordion.Body>
                <ul className="week-items">
                    {
                        topics.map( (topic , ind) => <TopicItem topic={topic} key={ind} />)
                    }
                </ul>
                <p className="week-summary">
                    {summary}
                </p>
                </Accordion.Body>
            </Accordion.Item>
    </div>
    )
}

function TopicItem( { topic } )
{
    return ( 
        <>
            <li className="week-items-heading">
                <h5 className="mb-0">
                {topic.title}
                </h5>
            </li>
            <SubTopicItem subTopics = {topic.subTopics} />
        </>
    )
}

function SubTopicItem( { subTopics } )
{
    return (
        <>
            {
                subTopics.map( (subtopic,ind) => 
                    <li key={ind}>
                        <img src="/images/page.png" alt="" />
                        <span>{subtopic}</span>
                    </li>
                )
            }
        </>
    )
}

function InstructorItem({ name , linkedin , imgSrc , description , title })
{
    return(
        <div className="instructor-profile">
        <a href={linkedin}>
            <img
            src={imgSrc}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            alt=""
            />
        </a>
        <div className="instructor-profile-details">
            <div className="position-relative">
            <h4 className="text-purple fw-bold">{name}</h4>
            <a
                className="instructor-social flex-center "
                href={linkedin}
            >
                {" "}
                <img
                src="/images/linkedin-blue.png"
                width="18px"
                alt=""
                />
            </a>
            </div>
            <h6 className="text-blue mb-4 fw-bold">
            {title}
            </h6>
            <a
            className="instructor-social-alternate flex-center  position-relative d-xl-none"
            href={linkedin}
            >
            {" "}
            <img
                src="/images/linkedin-blue.png"
                width="18px"
                alt=""
            />
            </a>
            <p className="text-gray fw-500 ">
                {description}
            </p>
        </div>
        </div>
    )
}