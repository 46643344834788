import React from 'react'
import { Link } from 'react-router-dom'

export default function Counselling() {
  return (
    <div className='counselling-page '>
        <section className="choose-us section-spacing " >
            <div className="container pt-5 pt-lg-4 pt-xl-0">
                <div className="section-heading ">
                    <h6>Why Choose Us</h6>
                    <h1>Learn From The Best In The Industry</h1>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="choose-us-content">
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-5">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/CV.png" width="30px" style={{width:'44px',paddingLeft:'6px'}} alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Prepare/Improve CV with Expert Feedback
                                </h5>
                            </div>
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-4">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/recommendation.png" width="38px" alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Get Recommendations and Referrals
                                </h5>
                            </div>
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-4">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/market placement.png" width="38px" alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Market Placement
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="choose-us-content">
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-5">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/interview.png" width="38px" alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Mock Interviews and Coaching Sessions
                                </h5>
                            </div>
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-4">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/profile.png" width="40px" alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Strengthen Professional Profile
                                </h5>
                            </div>
                            <div className="choose-us-highlights d-flex align-items-center gap-3 mt-4">
                                <div className="choose-us-rounded-wrapper ">
                                    <img src="./images/resume.png" style={{paddingLeft:'4px'}} alt="" />
                                </div>
                                <h5 className="text-purple fw-bold">
                                    Build Industry-ready Resume
                                </h5>
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        </section>
        <section className="offer section-spacing  bg-light" >
            <div className="offer-inner container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-heading text-left mb-5 ">
                            <h6>Drive Your Career With</h6>
                            <h1>Free Career Counselling</h1>
                            <p className='text-justify mt-4 mb-5'>We provide Outsourced IT Services For your business with high-skilled, smart and innovative solutions to fit your needs. Our experts have a vast experience and extensive knowledge and understanding of various tools and tricks.
                            Contact us now to get the best solutions the market has to offer.</p>
                            <Link className="generic-btn-1 w-md-50" style={{'width':'180px'}}  to={'/contact-us'}>Contact Us</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 align-items-center px-5 p-lg-0">
         
                        <img src="/images/counselling.png" style={{'width' : '100%' , 'height' : '450px' , 'objectFit' : 'contain'}} alt="" />
    
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
