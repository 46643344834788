import React ,{useState} from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './Form.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import trainings from '../../variables/trainings'
import axios from "../../axios"
import { ToastContainer , toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name Too Short')
    .max(50, 'Name Too Long')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  contact: Yup.string().length(11,'Invalid Number').required('Required'),
  city: Yup.string().min(2,'City length too short').max(50, 'City length too long').required('City Required') ,
  type : Yup.string().oneOf(['student','working professional']).required("Required") ,
  organization: Yup.string().max(50, 'Organization length too long'),
  designation: Yup.string().max(50, 'Designation length too long') ,
  discount: Yup.string().max(50, 'Discount code too long')

});

export default function Payment() {

  const nav = useNavigate()
  const {course , group} = useParams()
  const initialValues = {
    name : '',
    contact : '',
    email : '' ,
    organization : '' ,
    designation :'' ,
    city : '' ,
    type : '' ,
    discount : '' 
  }
  const [ isLoading , setIsLoading ] = useState(false)
  const [ error , setError] = useState("")
  const [ cnic , setCnic ] = useState("")
  const [ mobileNumber , setMobileNumber ] = useState("")

  const selectedCourse = trainings.find( el => el.slug == course )
  const selectedCourseFee = selectedCourse.fee
  let discount = 0

  if ( group  === 'five' )
    discount = 0.15
  else if ( group === 'three' )
    discount = 0.10

  let totalCourseFee = selectedCourseFee * ( 1 - discount )

  const handleSubmit = async (payload , { resetForm }) => {
    setIsLoading(true)
    console.log(payload)
    try{
      let { data , status } = await axios.post("/form?sheetName=" + course , payload)
      
      if (status === 200)
        nav(`/payment/${course}/${group}`)    

    }
    catch(err) {
      toast.error("Error Submitting Form" + JSON.stringify(err))
    }
    finally{
      setIsLoading(false)
    }
  }


  return (
    <div className='payment-page section-spacing'>
      <Helmet>
          <title>Registration Form || {selectedCourse.name}</title>
      </Helmet>
      <div className="container">
        <div className="google-form">
          <div className="section-heading mt-5 mt-xl-0  mb-5">
              <h6>Reserve your seat</h6>
              <h1>Registration Process</h1>
              <p className='mt-4'>To reserve your seat, you need to complete two steps.</p>
              <div className="d-flex align-items-center gap-4">
                <h4 className='fw-bold mb-0'  style={{whiteSpace:'nowrap'}}>Step 1:</h4>
                <p className='mb-0 fs-6 fs-sm-5'>Enter your details and get yourself registered</p>
              </div>
              <div className="d-flex align-items-center gap-4 mt-3">
                <h4 className='fw-bold mb-0' style={{whiteSpace:'nowrap'}}>Step 2:</h4>
                <p className='mb-0 fs-6 fs-sm-5'>Complete your payment or choose "Pay Later" if you wish to skip paying right now.</p>
              </div>
              <div className='mt-4 d-sm-flex text-center text-sm-start align-items-center gap-3'>
                <img src="/images/info.png" width={'30px'} alt="" />
                <p className='mb-0 fw-semibold'>Payment required to reserve seat. If registered, proceed to payment page.</p>
              </div>
          </div>
          <div className="section-heading mt-5 mt-xl-0  mb-5">
              <h1>Enter your details</h1>
          </div>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} >
            {({ errors, touched }) => (
                <Form className='form-wrapper mx-auto'>
                  <div className="input-wrapper">
                    <label htmlFor="name">Full Name: *</label>
                    <Field name="name" type="text" placeholder='Enter your Name'  />
                    {errors.name && touched.name ? (<div className='text-danger fw-bold'>{errors.name}</div>) : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="email">Email: *</label>
                    <Field name="email" type="text" placeholder='Enter your email'  />
                    {errors.email && touched.email ? (<div className='text-danger fw-bold'>{errors.email}</div>) : null}

                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="number">Contact Number: *</label>
                    <Field name="contact" type="text" placeholder='Enter your contact number'  />
                    {errors.contact && touched.contact ? (<div className='text-danger fw-bold'>{errors.contact}</div>) : null}

                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="city">City: *</label>
                    <Field name="city" type="text" placeholder='Enter your city'  />
                    {errors.city && touched.city ? (<div className='text-danger fw-bold'>{errors.city}</div>) : null}

                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="city">Your are a : *</label>
                    <div className='d-flex align-items-center gap-2'>
                      <Field type="radio" className='mb-0 w-auto' name='type' value={'student'}  />
                      <span>Student</span>
                    </div>
                    <div className='d-flex align-items-center gap-2 mt-4 mb-3'>
                      <Field type="radio" className='mb-0 w-auto' name='type' value={'working professional'}  />
                      <span>Working Professional</span>
                    </div>
                    {errors.type && touched.type ? (<div className='text-danger fw-bold'>{errors.type}</div>) : null}

                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="organization">Organization/Institution name: </label>
                    <Field name="organization" type="text" placeholder='Enter your answer'  />
                    {errors.organization && touched.organization ? (<div className='text-danger fw-bold'>{errors.organization}</div>) : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="designation">Designation/Semester: </label>
                    <Field name="designation" type="text" placeholder='Enter your answer'  />
                    {errors.designation && touched.designation ? (<div className='text-danger fw-bold'>{errors.designation}</div>) : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="discount">Enter discount code (if available) : </label>
                    <Field name="discount" type="text" placeholder='Enter your answer'  />
                    {errors.discount && touched.discount ? (<div className='text-danger fw-bold'>{errors.discount}</div>) : null}
                  </div>
                  {
                    isLoading ? 
                    <img src='/images/loader.png' className='loader' width={'80px'} /> 
                    :
                    <div className='d-flex gap-4'>
                      <button type='submit' className='generic-btn-1 d-inline-flex ' style={{maxWidth:'250px'}} >Register </button>
                      <Link to={`/payment/${course}/${group}`} className='generic-btn-1 d-inline-flex ' style={{maxWidth:'250px'}}>Skip To Payment</Link>
                    </div>

                  }
                </Form>
            )}
            </Formik>
            <ToastContainer/>
        </div>
      </div>
    </div>
  )
}
