import React from 'react'
import './Instructors.css'
import ContactBanner from '../../components/ContactBanner/ContactBanner'
import instructors from '../../variables/instructors'
import { Helmet } from 'react-helmet'

export default function Instructors() {

  return (
    <div className="site-wrapper">
        <Helmet>
            <title>Our Instructors || Transformatics</title>
            <meta name="description" content="Instructors for data analytics courses at Transformatics" />
        </Helmet>
        <section className="instructors-grid section-spacing">
            <div className="container">
            <div className="section-heading">
                <h6>Skilled Experts</h6>
                <h1>Instructors</h1>
            </div>
            <div className="instructor-slide mt-5">
              {
                  instructors.map( instructor => <InstructorCard {...instructor} key={instructor.linkedin} />)
              }
            </div>
            </div>
        </section>
        <ContactBanner/>
    </div>

  )
}

function InstructorCard( { name , linkedin , title , imgSrc } )
{ 

    return (
        <div className="instructor-slide-item">
            <div className="instructor-slide-item-inner">
                <div className="instructor-img-wrapper">
                <img
                    src={imgSrc}
                    style={{ borderRadius: "50%" }}
                    alt=""
                />
                <div className="instructor-img-overlay"></div>
                <a
                    className="instructor-link"
                    href={linkedin}
                >
                    <img
                    src="./images/linkedin-blue.png"
                    width="20px"
                    alt=""
                    />
                </a>
                </div>
                <h4 className="text-center text-purple mt-4">
                {" "}
                <a
                    href={linkedin}
                    className="text-purple fw-bold"
                >
                    {name}
                </a>{" "}
                </h4>
                <h6 className="text-blue mt-2 text-center">
                {" "}
                    {title}
                </h6>
            </div>
        </div>
    )
}