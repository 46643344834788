import React , {useRef, useState} from 'react'
import { toast } from 'react-toastify' 
import axios  from '../../axios'
import { Link } from 'react-router-dom'

const CardPaymentWrapper = ({ amount , setAmountError }) => {

    const formRef = useRef()
    const [loading , setLoading] = useState(false)
    const [payloadFetched , setPayloadFetched] = useState(false)
    const [payload , setPayload] = useState({})
  
    const getHashedPayload = async () => {
  
      if ( amount === "" || amount <= 100 )
      {
        setAmountError( "Enter an amount greater than 100")
        return
      }
  
      setLoading(true)
      try{
        const {data} = await axios.post('/payment/card',{amount : amount * 100})
        console.log(data)
        setPayload(data)
        setPayloadFetched(true)
  
      }
      catch(err){
        console.log(err)
        toast.error('An error occurred. Try again')
      }
      finally{
        setLoading(false)
      }
  
    }
    return(
      <>
        <form method='POST' ref={formRef} action="https://payments.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform" className='d-none'>
          {
            Object.keys(payload).map( (key) => <input key={key}  type='text' name={key} value={payload[key]} />)
          }
        </form>
          {
            payloadFetched ?
            <div className="d-flex">
              <button className='generic-btn-1 mt-5' type='submit' onClick={ e => formRef.current.submit()}>Pay Now</button>
            </div>
            :
            <div className="d-flex gap-4  mt-5">
              <button className="generic-btn-1" onClick={getHashedPayload}>Pay with card</button>  
              <Link to={'/registration/success'} className="generic-btn-1" >Pay Later</Link>
            </div>
          }
          {
            loading && <img src='/images/loader.png' className='loader' width={'80px'} /> 
          }
        
      </>
    )
  
  }

export default CardPaymentWrapper