import './App.css';
import './index.css'
import { Routes , Route , ScrollRestoration } from 'react-router-dom'
import Home from './pages/Home/Home';
import About from './pages/About/About'
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import NotFound from './pages/NotFound/NotFound';
import React from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Training from './pages/Training/Training';
import Contact from './pages/Contact/Contact';
import JazzCashSuccess from './pages/Success/JazzCashSuccess';
import CardSuccess from './pages/Success/CardSuccess';
import Instructors from './pages/Instructors/Instructors';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Course from './pages/Course/Course';
import PricePlan from './pages/PricePlan/PricePlan';
import Consultancy from './pages/Consultancy/Consultancy';
import Counselling from './pages/Counselling/Counselling';
import CorporateCourse from './pages/CorporateCourse/CorporateCourse';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';
import ReactGA from 'react-ga';
import Form from './pages/Form/Form';
import Terms from './pages/Terms/Terms';
import VerifyPayment from './pages/VerifyPayment/VerifyPayment';
import Payment from './pages/Payment/Payment';
import RegistrationSuccess from './pages/Success/RegistrationSuccess';
import StandAlonePayment from './pages/Payment/StandAlonePayment';

function App() {

  ReactGA.initialize('G-V6Y262DJB5');
  return (
    <div className="App">

      <ScrollToTop/>
      <Header/>

        <Routes>

          <Route Component={GoogleAnalytics}>

            <Route Component={Home} path='/' exact />

            <Route Component={About} path='/about-us' exact />

            <Route Component={Contact} path='/contact-us' exact />

            <Route Component={Training} path='/trainings' exact />

            <Route Component={Course} path='/trainings/:slug' exact />

            <Route Component={CorporateCourse} path='/corporate-trainings/:slug' exact />

            <Route Component={Instructors} path='/instructors' exact />

            <Route Component={Consultancy} path='/consultancy' exact />

            <Route Component={Counselling} path='/counselling' exact />

            <Route Component={PricePlan} path='/price-plan/:slug' exact />

            <Route Component={PricePlan} path='/price-plan/:slug' exact />

            <Route Component={PricePlan} path='/price-plan/:slug' exact />

            <Route Component={Form} path='/form/:course/:group' exact />

            <Route Component={Payment} path='/payment/:course/:group' exact />

            <Route Component={StandAlonePayment} path='/recurring-payment' exact />

            <Route Component={JazzCashSuccess} path='/jazzcash/success' exact />

            <Route Component={CardSuccess} path='/card/success' exact />

            <Route Component={RegistrationSuccess} path='/registration/success' exact />

            <Route Component={VerifyPayment} path='/verify-payment' exact />

            <Route Component={Terms} path='/terms-and-conditions' exact />

            <Route Component={NotFound} path='*' exact />

          </Route>
          
        </Routes>

      <Footer/>
      <WhatsAppButton/>

    </div>
  );
}

export default App;
