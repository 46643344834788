const  instructors = [
    {
        name : 'Nouman Mir' ,
        title : 'Data Analytics Expert' ,
        linkedin : "https://www.linkedin.com/in/nauman-mir-1b593285/" ,
        imgSrc : require('../images/instructor-1.png')
    } ,
    {
        name: 'Ammad Tahir',
        title: 'DWH & BI Expert',
        linkedin: 'https://www.linkedin.com/in/ammad-tahir-a3687685/',
        imgSrc: require('../images/ammad-center.avif')
    },
    {
        name: 'Umar Afzal',
        title: 'Senior IT & Telecom Executive',
        linkedin: 'https://www.linkedin.com/in/umerafzal/',
        imgSrc: require('../images/umer-afzal.jpg')
    },
    {
        name: 'Bilal Bin Munir',
        title: 'Analytics Ecosystem Advisor',
        linkedin: '',
        imgSrc: require('../images/bilal-bin-munir.avif')
    } ,
    {
        name: 'Salman Faiz Cheema',
        title: 'Business Analytics Expert',
        linkedin: 'https://www.linkedin.com/in/salman-faiz-cheema-447bba4/',
        imgSrc: require('../images/instructor-5.jpg')
    } ,
    {
        name: 'Saba Farooqi',
        title: 'BI-Modeling & Reporting Expert',
        linkedin: 'https://www.linkedin.com/in/saba-farooqi-44b88121b/',
        imgSrc: require('../images/instructor-4.png')
    } ,
    {
        name: 'Umer Ali',
        title: 'Big Data & DWH Expert',
        linkedin: 'https://www.linkedin.com/in/mumerali/',
        imgSrc: require('../images/instructor-6.jpg')
    },
    {
        name: 'Yasir Mehmood',
        title: 'SQL & Power-BI Expert',
        linkedin: 'https://www.linkedin.com/in/yasir-mahmood-b42324132/',
        imgSrc: require('../images/instructor-7.jpg')
    },
    {
        name: 'Umer Munir',
        title: 'Cloud & DevOps Expert',
        linkedin: 'https://www.linkedin.com/in/umer-munir-1a28a656/',
        imgSrc: require('../images/instructor-8.jpg')
    } ,

    {
        name: 'Hajra Asif',
        title: 'Power-BI & Tableau Expert',
        linkedin: 'https://www.linkedin.com/in/hajirah-asif-513735147',
        imgSrc: require('../images/instructor-13.jpg')
    },
    {
        name: 'Syed Abbas Rizvi ',
        title: 'Data Engineer ',
        linkedin: 'https://www.linkedin.com/in/muhammadrizvi/',
        imgSrc: require('../images/syed-abbas.jpg')
    }, 
    {
        name: 'Usman Jamal',
        title: 'Sr. Data Engineering Specialist ',
        linkedin: 'https://www.linkedin.com/in/usman-jamal-b9308473/',
        imgSrc: require('../images/usman-jamal.jpg')
    }, 
    {
        name: 'Rahim Nawaz Khan',
        title: 'Legal, Corporate and Tax Advisor ',
        linkedin: 'https://www.linkedin.com/in/rahim-nawaz-khan-30075849/',
        imgSrc: require('../images/rahim-nawaz.jpg')
    }, 
    {
        name: "Faraz Shahid" , 
        title: "Senior Data Scientist" , 
        linkedin : "https://www.linkedin.com/in/fshahid/" ,
        imgSrc: require("../images/instructor-16.avif")
    } , 
    {
        name: "Usman Shahrukh" , 
        title: "Senior Data Scientist / Analyst" , 
        linkedin : "https://www.linkedin.com/in/usmanshahrukh/" ,
        imgSrc: require("../images/instructor-17.jpg")
    } ,

    {
        name: "Haroon Latif" , 
        title: "Business Analyst" , 
        linkedin : "https://www.linkedin.com/in/h-latif/" ,
        imgSrc: require("../images/haroon-latif.jpg")
    }
  
]

export default instructors